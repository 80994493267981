var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"directinvite"}},[_vm._m(0),_c('div',{staticClass:"container d-flex justify-content-center align-items-center"},[_c('validation-observer',{ref:"refFormObserver"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.submit()},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('b-card-text',{staticClass:"text-center m-2"},[_c('b-link',{attrs:{"to":{ name: 'login' }}},[_c('span',{staticStyle:{"font-size":"17px"}},[_vm._v(" Já possuo cadastro")])])],1),_c('validation-provider',{attrs:{"name":"image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Foto do palestrante","label-for":"image"}},[_c('b-form-file',{attrs:{"id":"image","placeholder":"Selecione a foto...","drop-placeholder":"Arraste seu arquivo para aqui..."},model:{value:(_vm.form.image),callback:function ($$v) {_vm.$set(_vm.form, "image", $$v)},expression:"form.image"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome Completo","label-for":"name","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"cpf","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"CPF","label-for":"cpf","state":errors.length > 0 ? false : null}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"id":"cpf","state":errors.length > 0 ? false : null},model:{value:(_vm.form.cpf),callback:function ($$v) {_vm.$set(_vm.form, "cpf", $$v)},expression:"form.cpf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Email","label-for":"email","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"id":"email","autocomplete":'false',"state":errors.length > 0 ? false : null},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"is_not_brazilian"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Não sou brasileiro","label-for":"is_not_brazilian","state":errors.length > 0 ? false : null}},[_c('b-form-checkbox',{attrs:{"id":"is_not_brazilian","name":"is_not_brazilian","unchecked-value":"not_accepted","autocomplete":'false',"state":errors.length > 0 ? false : null},model:{value:(_vm.form.is_not_brazilian),callback:function ($$v) {_vm.$set(_vm.form, "is_not_brazilian", $$v)},expression:"form.is_not_brazilian"}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])],1)]}}])}),_c('validation-provider',{attrs:{"name":"phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Celular","label-for":"phone","state":errors.length > 0 ? false : null}},[_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('(##) #####-####'),expression:"'(##) #####-####'"}],attrs:{"id":"phone","state":errors.length > 0 ? false : null},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"name_badge","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nome para crachá","label-for":"name_badge","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"id":"name_badge","state":errors.length > 0 ? false : null},model:{value:(_vm.form.name_badge),callback:function ($$v) {_vm.$set(_vm.form, "name_badge", $$v)},expression:"form.name_badge"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"pronome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Como gostaria de ser chamado?","label-for":"pronome","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"pronome","options":[{text:'Ele/Dele', value: 'Ele/Dele'}, {text:'Ela/Dela', value: 'Ela/Dela'}],"reduce":function (option) { return option.value; },"label":"text","state":errors.length > 0 ? false : null},model:{value:(_vm.form.pronome),callback:function ($$v) {_vm.$set(_vm.form, "pronome", $$v)},expression:"form.pronome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"lattes_link","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Currículo Lattes","label-for":"lattes_link","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"id":"lattes_link","state":errors.length > 0 ? false : null},model:{value:(_vm.form.lattes_link),callback:function ($$v) {_vm.$set(_vm.form, "lattes_link", $$v)},expression:"form.lattes_link"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"instagram"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Instagram","label-for":"instagram","state":errors.length > 0 ? false : null}},[_c('b-form-input',{attrs:{"id":"instagram","state":errors.length > 0 ? false : null},model:{value:(_vm.form.instagram),callback:function ($$v) {_vm.$set(_vm.form, "instagram", $$v)},expression:"form.instagram"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"mini_curriculo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Mini Currículo","label-for":"mini_curriculo","state":errors.length > 0 ? false : null}},[_c('b-form-textarea',{attrs:{"id":"mini_curriculo","placeholder":"Digite o miní currículo","rows":"3","max-rows":"6","state":errors.length > 0 ? false : null},model:{value:(_vm.form.mini_curriculo),callback:function ($$v) {_vm.$set(_vm.form, "mini_curriculo", $$v)},expression:"form.mini_curriculo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('div',{staticClass:"d-flex mt-5"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","block":"","type":"submit","disabled":_vm.isBusy}},[(_vm.isBusy)?_c('b-spinner'):[_vm._v(" Salvar ")]],2)],1),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('b-link',[_c('span',[_vm._v(" Desenvolvido por PhiCoding")])])],1)],1)],1),_c('div')],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" Cadastrar Palestrante ")])])}]

export { render, staticRenderFns }